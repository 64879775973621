import * as React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import yellowArrow from "../images/yellow-arrow-1.png"

const useStyles = makeStyles(theme => ({
  Titel: {
    fontSize: "1.8rem",
    fontWeight: "600",
    color: theme.palette.service.main,
    marginTop: 15,
  },
  Content: {
    fontSize: "1.1rem",
    lineHeight: "1.5rem",
    marginTop: ".5em!important",
    textAlign: "left",
  },
  Background: {
    backgroundColor: "#333333",
    borderRadius: 10,
    padding: "0px 30px 25px 40px",
    textAlign: "left",
    border: `1px rgba(255,255,255,.15) solid`,
    "&:after": {
      width: 25,
      content: '" "',
      display: "block",
      position: "absolute",
      background: `url(${yellowArrow}) no-repeat fixed right 50%`,
      height: 103,
      marginLeft: -40,
      marginTop: 13,
    },
  },
  TeaserTitel: {
    fontSize: "2.5rem",
    fontWeight: "600",
    color: theme.palette.service.main,
    marginTop: 15,
  },
  TeaserContent: {
    fontSize: "1.25rem",
    fontWeight: 300,
    lineHeight: "1.5rem",
    marginTop: 15,
  },
  TeaserBackground: {
    backgroundColor: "#333333",
    borderRadius: 10,
    padding: "0px 30px 25px 40px",
    textAlign: "left",
    border: `1px rgba(255,255,255,.15) solid`,
    "&:after": {
      width: 25,
      content: '" "',
      display: "block",
      position: "absolute",
      background: `url(${yellowArrow}) no-repeat right 50%`,
      height: 103,
      marginLeft: -40,
      marginTop: 20,
    },
  },
}))

const Component = function (props) {
  const classes = useStyles()

  return (
    <Grid
      container
      className={
        props.variant === "teaser"
          ? classes.TeaserBackground
          : classes.Background
      }
      maxWidth={props.maxWidth}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
        style={{ width: "100%" }}
      >
        <Typography
          variant="h2"
          className={
            props.variant === "teaser" ? classes.TeaserTitel : classes.Titel
          }
        >
          {props.titel}
        </Typography>
        <Typography
          variant="body"
          className={
            props.variant === "teaser" ? classes.TeaserContent : classes.Content
          }
        >
          {props.description}
        </Typography>
        {props.button && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {props.button}
          </Stack>
        )}
      </Stack>
    </Grid>
  )
}
export default Component

Component.defaultProps = {
  titel: "",
  description: "",
  button: false,
  variant: "default",
  maxWidth: "auto",
}
