import * as React from "react"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import ImageWavesLight from "../images/bg-waves-light.png"
import ContentBox from "../components/contentBox"
import ButtonYellow from "../components/buttonYellow"

const SupportPage = () => (
  <Layout>
    <Seo title="Start EN" description="Start EN" keywords="Start" />
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `rgba(0,0,0,.85) url(${ImageWavesLight}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <Header siteName="support" rootPath="/" />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          marginTop={10}
          marginBottom={10}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h1" fontSize="5rem">
              SUPPORT
            </Typography>
            <Typography variant="body" fontSize="2rem" textAlign="center">
              If you wish to scale your Restreamer or Core operations, we would
              be happy to support you with our professional services offers.
              Please fill out the below form, and we will be in touch shortly.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
            marginBottom={11}
          >
            <ContentBox
              titel="Initial setup"
              description="To get you up and running in no time with Restreamer and Core."
              variant="teaser"
            />
            <ContentBox
              titel="Custom configuration"
              description="We assist you in customizing our apps for your specific business and technical needs."
              variant="teaser"
            />
            <ContentBox
              titel="Dedicated support"
              description="To ensure your working environment is optimized to reach your goals."
              variant="teaser"
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#333`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <ButtonYellow
            variant="form"
            titel="Contact"
            href="#mailgo"
            dataaddress="support"
            datadomain="datarhei.com"
          />
        </Stack>
      </Container>
    </Stack>
  </Layout>
)

export default SupportPage
